import React from 'react'
import { Helmet } from 'react-helmet'
import { graphql } from 'gatsby'

import Layout from '../components/Layout.jsx'
import LayoutWithSideMenu from '../components/LayoutWithSideMenu'
import Container from '../components/Container'

export default({
  data
}) => {
  const { markdownRemark } = data
  const { frontmatter, html } = markdownRemark
  const breadcrumbs = [
    {
      name: 'Главная',
      path: '/'
    },
    {
      name: 'Статьи и подборки',
      path: '/blog'
    },
    {
      name: frontmatter.name,
      path: frontmatter.path
    }
  ]
  return (
    <Layout>
      <Container>
        <Helmet>
          <title>{frontmatter.title}</title>
          <meta name="description" content={frontmatter.description} />
        </Helmet>
        <LayoutWithSideMenu heading={frontmatter.heading} breadcrumbs={breadcrumbs}>
          <img src={frontmatter.thumb && frontmatter.thumb.publicURL} alt={frontmatter.title} />
          <div
            dangerouslySetInnerHTML={{ __html: html }}
          />
        </LayoutWithSideMenu>
      </Container>
    </Layout>
  )
}

export const pageQuery = graphql`
  query BlogPostByPath($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        name
        path
        title
        heading
        description
        thumb {
          publicURL
        }
      }
    }
  }
`
